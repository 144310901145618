import * as React from "react";
import { Link } from "gatsby";
import BrandLogo from "./icons/brand-logo";

// import logo from "../img/logo.svg";
// import facebook from "../img/social/facebook.svg";
// import instagram from "../img/social/instagram.svg";
// import twitter from "../img/social/twitter.svg";
// import vimeo from "../img/social/vimeo.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black-bis has-text-centered">
        <div
          style={{ display: "flex", paddingBottom: "2em" }}
          className="content is-justify-content-center"
        >
          <div style={{ width: "14em", height: "10em" }}>
            <a href="/">
              <BrandLogo />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="container">
            <div
              style={{ maxWidth: "100vw" }}
              className="columns has-text-left section is-small"
            >
              <div className="column is-3">
                <p className="footer-title">Algona</p>
                <p class="location-attorney">Thomas Lipps</p>
                <p class="location-address">
                  6 East State Street
                  <br />
                  Algona, IA 50511
                </p>
                <p class="location-email">
                  <a href="mailto:algona@sjlawoffice.com">
                    algona@sjlawoffice.com
                  </a>
                </p>
                <p class="location-phone">
                  <b>Phone:</b> <a href="tel:(515) 295-9494">(515) 295-9494</a>
                </p>
                <p>
                  <b>Fax:</b> <a href="tel:(515) 295-9493">(515) 295-9493</a>
                </p>
              </div>
              <div className="column is-3">
                <p className="footer-title">Britt</p>
                <p class="location-attorney">David Siegrist & Brian Jones</p>
                <p class="location-address">
                  94 Main Avenue North
                  <br />
                  Britt, IA 50423
                </p>
                <p class="location-email">
                  <a href="mailto:britt@sjlawoffice.com">
                    britt@sjlawoffice.com
                  </a>
                </p>
                <p class="location-phone">
                  <b>Phone:</b> <a href="tel:(641) 843-4451">(641) 843-4451</a>
                </p>
                <p>
                  <b>Fax:</b> <a href="tel:(641) 843-3730">(641) 843-3730</a>
                </p>
              </div>
              <div className="column is-3">
                <p className="footer-title">Forest City</p>
                <p class="location-attorney">Steven Bakke</p>
                <p class="location-address">
                  150 N Clark St
                  <br />
                  Forest City, IA 50436
                </p>
                <p class="location-email">
                  <a href="mailto:forestcity@sjlawoffice.com">
                    forestcity@sjlawoffice.com
                  </a>
                </p>
                <p class="location-phone">
                  <b>Phone:</b> <a href="tel:(641) 585-2530">(641) 585-2530</a>
                </p>
                <p>
                  <b>Fax:</b> <a href="tel:(641) 585-2023">(641) 585-2023</a>
                </p>
              </div>
              <div className="column is-3">
                <section className="menu">
                  <p className="footer-title">Site Navigation</p>
                  <ul className="link-list" style={{}}>
                    <li>
                      <Link to="/" className="">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="" to="/staff/">
                        Attorneys/Staff
                      </Link>
                    </li>
                    <li>
                      <Link className="" to="/practice-areas/">
                        Practice Areas
                      </Link>
                    </li>
                    <li>
                      <a className="item" href="/contact/">
                        Contact
                      </a>
                    </li>
                    <li>
                      <a
                        className=""
                        href="/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Admin
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="is-size-7" style={{ padding: "4rem 0 2rem 0" }}>
          <div class="container">
            <p>© 2023 All Rights Reserved | SJLB</p>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
