import * as React from "react";

export default function brandLogo() {
  return (
    <svg
      id="SJLB_logo"
      aria-labelledby="logoTitle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 296.58 269.26"
      style={{ height: "100%" }}
    >
      <title id="logoTitle">Go To Home Page</title>
      <path
        fill="#bd8d14"
        id="S"
        d="M0 212.67c5.14 2.55 10.17 5.37 15.45 7.62 17.48 7.46 35.54 8.65 53.7 3.2 19.59-5.88 29.12-22.37 25.78-42.6-1.65-10.02-6.25-18.17-14.51-23.87-11.12-7.66-22.66-14.7-33.85-22.25-9.76-6.58-19.73-12.98-28.85-20.38-21.7-17.61-24.04-58.52 6.98-79.05 14.66-9.7 31.07-11.94 48.03-10.37 9.7.9 19.28 3.21 28.93 4.89v15.48c-6.34-1.87-12.67-4.23-19.21-5.58-14-2.9-28.12-3.75-41.76 1.7C14.05 52.1 12.84 78.01 20.62 94.52c2.99 6.34 8.13 10.86 13.75 14.61 10.73 7.15 21.89 13.64 32.59 20.83 9.56 6.42 19.65 12.46 27.94 20.32 27.03 25.62 17.24 71.32-17.61 84.47-19.17 7.23-38.59 6.34-57.96 1.1-6.59-1.78-12.9-4.61-19.34-6.95v-16.24Z"
      />
      <path
        fill="#bd8c12"
        id="J"
        d="M67.99 267.05V253.4c7.35 2.26 14.63 3.39 22.24 2.33 12.26-1.71 20.33-8.49 25.02-19.69 4.98-11.9 6.27-24.52 6.33-37.18.23-45.48.13-90.97.16-136.45v-4.58h13.93c.07.99.2 1.93.2 2.88 0 46.1.07 92.2-.11 138.3-.06 15.19-2.03 30.16-8.67 44.1-6.88 14.45-18.09 23.24-34.09 25.65-3.22.48-4.65.51-9.34.51-5.32-.01-10.63-.52-15.67-2.21Z"
      />
      <path
        fill="#bd8d14"
        id="L"
        d="M178.21 207.94V.27c.85-.09 1.55-.22 2.25-.22 18.94.02 37.89-.2 56.82.16 13.27.25 25.42 4.23 35.3 13.57 12.35 11.67 15.55 26.48 13.38 42.57-2.72 20.19-14.21 33.75-33.11 41.12-.77.3-1.54.61-2.65 1.06 5.33 1.34 10.4 2.12 15.1 3.88 19.25 7.17 29.55 21.42 31.07 41.69 2.02 26.88-10.79 49.75-35.59 59.36-6.64 2.57-14.03 4.23-21.13 4.45-20.03.62-40.09.28-60.14.3-.35 0-.7-.14-1.31-.28Zm14.63-13.01c15.29 0 30.18.33 45.05-.11 10.19-.3 19.95-2.96 28.27-9.33 11.77-9.03 15.8-21.64 15.57-35.77-.35-21.31-10.56-34.09-31.2-39.56-4.73-1.25-9.63-2.4-14.48-2.51-14.34-.33-28.69-.12-43.2-.12v87.41Zm-.03-101.81c13.29 0 26.28 1.02 39.04-.23 22.62-2.21 40.95-17.6 39.68-44.75-.6-12.81-5.16-23.46-17.25-29.62-6.92-3.52-14.43-4.94-22.09-5.07-12.54-.22-25.08-.09-37.63-.09-.57 0-1.14.17-1.75.26v79.49Z"
      />
      <path
        fill="#bd8d14"
        id="B"
        d="M246.47 222.94V236h-97.74V28.29h14.28v194.65h83.46Z"
      />
    </svg>
  );
}
