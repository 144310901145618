import React, { useState } from "react";
import { Link } from "gatsby";

export default function ButtonLink({ to, href, icon, stroke, fill, children }) {
  const [isFocused, setIsFocused] = useState(false);

  const focusedClass = isFocused ? " is-light" : "";

  let Tag;

  if (href) {
    Tag = "a";
  } else if (to) {
    Tag = Link;
  } else {
    Tag = "button";
  }

  return (
    <Tag
      className={`button is-primary${focusedClass}`}
      style={{
        color: "black",
        fontWeight: "bold",
        minWidth: "9em",
        border: "none",
        textDecoration: "none",
        width: "fit-content",
      }}
      {...(to && (to = { to }))}
      {...(href && (href = { href }))}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      {children}
      {icon && (
        <icon
          className="icon"
          style={{
            display: "inline-block",
            padding: "0 25px 0 15px",
            stroke: { stroke },
            fill: { fill },
          }}
        >
          {icon}
        </icon>
      )}
    </Tag>
  );
}
